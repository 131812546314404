<template>
  <v-dialog v-model="show" persistent width="450">
    <v-card>
      <v-toolbar color="secondary" dark flat dense>
        <v-toolbar-title>Asignar técnico</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-2">
        <v-row dense>
          <v-col cols="12">
            <v-select
              v-model="technician"
              :items="technicians"
              color="secondary"
              item-color="secondary"
              item-value="_id"
              item-text="name"
              outlined
              dense
              label="Técnico asignado"
              hint="Aún con técnico asignado, los demás técnicos podrán ver y modificar la reparación. Este campo solo ayuda a que el técnico pueda filtrar mejor sus reparaciones"
              persistent-hint
              :disabled="loading"
            ></v-select>
          </v-col>
          <v-col v-if="technician && hasPermission(710)" cols="12">
            <v-text-field
              label="Comisión fija (opcional)"
              hint="Se ignorará el porcentaje del técnico para utilizar este"
              persistent-hint
              v-model="commissionPercent"
              outlined
              dense
              :error-messages="errors.commissionPercent"
              @input="validateCommissionPercent()"
              @blur="validateCommissionPercent()"
            >
              <template #append> % </template>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <div class="d-flex">
              <v-btn
                color="secondary"
                outlined
                class="mr-2"
                @click="$emit('cancel')"
              >
                <v-icon small class="mr-2">mdi-close</v-icon>
                <span>Cancelar</span>
              </v-btn>
              <v-btn
                color="primary"
                :loading="loading"
                :disabled="!isFormValid"
                @click="submit()"
              >
                <v-icon small class="mr-2">mdi-content-save</v-icon>
                <span>Guardar</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { integer, minValue, maxValue } from "vuelidate/lib/validators";
import serverRequestMixin from "@/mixins/serverRequest.mixin";

export default {
  mixins: [validationMixin, serverRequestMixin],

  props: {
    show: { type: Boolean, default: false },
    invoiceId: { type: [Number, String], required: true },
    currentCommissionPercent: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      loading: false,
      error: "",
      technicians: [],
      technician: "",
      commissionPercent: this.currentCommissionPercent,
      errors: {
        commissionPercent: [],
      },
    };
  },

  validations: {
    commissionPercent: {
      integer,
      minValue: minValue(0),
      maxValue: maxValue(100),
    },
  },

  computed: {
    ...mapGetters(["user", "hasPermission"]),

    isFormValid() {
      if (this.errors.commissionPercent.length) return false;
      if (!this.technician) return false;
      return true;
    },
  },

  mounted() {
    this.getTechnicians();
  },

  methods: {
    async getTechnicians() {
      this.loading = true;

      try {
        const serverResponse = await this.getRequest(
          `/users/byBusiness/${this.user.businessId}`,
          true,
          { role: 3 }
        );
        this.loading = false;

        this.technicians = serverResponse.users;
        if (this.technicians.length) this.technician = this.technicians[0]._id;
      } catch (error) {
        this.loading = false;
        if (error.data) this.error = error.data.message;
        else
          this.error = "Error inesperado, favor de contactar al desarrollador";
        if (error.status >= 500) console.error(error);
      }
    },

    validateCommissionPercent() {
      const errors = [];
      this.$v.commissionPercent.$touch();
      !this.$v.commissionPercent.integer &&
        errors.push("Ingresa una cantidad válida, sin decimales");
      !this.$v.commissionPercent.minValue &&
        errors.push("El valor mínimo es de 0");
      !this.$v.commissionPercent.maxValue &&
        errors.push("El valor máximo es de 100");
      this.errors.commissionPercent = errors;
    },

    async submit() {
      this.validateCommissionPercent();
      if (!this.technicians.length || !this.isFormValid) return;
      this.loading = true;

      try {
        const technicianSelected = this.technicians.find(
          (e) => e._id === this.technician
        );
        const inputData = {
          technician: this.technician,
          commissionPercent: this.commissionPercent,
          customLogMessage: `Le asignó esta reparación a ${technicianSelected.name}`,
        };

        const serverResponse = await this.putRequest(
          `/repairs/${this.invoiceId}`,
          inputData
        );
        this.loading = false;

        this.$emit("technicianSelected", {
          technicianSelected,
          newCommissionPercent:
            serverResponse.repair.commission.commissionPercent,
          logs: serverResponse.repair.logs,
        });
      } catch (error) {
        console.error(error);
        this.loading = false;
        if (error.data) this.error = error.data.message;
        else
          this.error = "Error inesperado, favor de contactar al desarrollador";
        if (error.status >= 500) console.error(error);
      }
    },
  },
};
</script>
